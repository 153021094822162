<template lang="html">
  <div class="forgetPassword">
    <div class="title">忘记密码</div>
    <div class="box">
      <div class="list">
        <div class="listTitle">账号</div>
        <div class="listBox">
          <van-field v-model="parames.mobile" placeholder="请输入账号" />
        </div>
      </div>
      <div class="list">
        <div class="listTitle">验证码</div>
        <div class="codeBox">
          <div class="left">
            <van-field v-model="parames.code" placeholder="请输入验证码" />
          </div>
          <div class="right">
            <span @click="getCode" v-if="isShowText">获取验证码</span>
            <span v-else>{{ time }}秒重新获取</span>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="listTitle">新密码</div>
        <div class="listBox">
          <div class="">
            <van-field
              v-model="parames.password"
              :type="isPassWord ? 'password' : 'text'"
              placeholder="请输入新密码"
            />
          </div>
          <img
            :src="
              isPassWord ? require('../assets/image/off.png') : require('../assets/image/on.png')
            "
            class="passIcon"
            @click="isPassWord = !isPassWord"
            alt=""
          />
        </div>
      </div>
      <div class="base">
        <div class="forget" @click="$router.go(-1)">登录</div>
        <div class="btn" @click="confirmSubmit">
          <van-button type="primary" round>
            确定
            <img src="../assets/image/xiangyou2.png" alt="" />
          </van-button>
        </div>
      </div>
    </div>
    <div class="bar"></div>
  </div>
</template>

<script>
export default {
  name: 'forgetPassword',
  data() {
    return {
      parames: {
        mobile: '',
        code: '',
        password: ''
      },
      isPassWord: true,
      isShowText: true,
      timeou: null,
      time: 59
    };
  },
  methods: {
    getCode() {
      if (!this.parames.mobile) {
        this.$toast('请输入手机号');
        return;
      }
      this.$require
        .HttpPost(
          '/purchaseLogin/sendCode',
          {
            mobile: this.parames.mobile,
            type: 2
          },
          { isTokn: true, isURl: true }
        )
        .then(() => {
          this.$toast('短信验证码已发送，请注意查收');
          this.time = 59;
          this.isShowText = false;
          this.timer();
        });
    },
    timer() {
      this.timeou = setInterval(() => {
        --this.time;
        if (this.time <= 0) {
          clearInterval(this.timeou);
          this.time = 59;
          this.isShowText = true;
        }
      }, 1000);
    },
    confirmSubmit() {
      if (!this.parames.mobile) {
        this.$toast('请输入手机号码');
        return;
      }
      if (!this.parames.password) {
        this.$toast('请输入新登录密码');
        return;
      }
      if (!this.parames.code) {
        this.$toast('请输入验证码');
        return;
      }
      console.log(this.parames);
      this.$require
        .HttpPost('/purchaseLogin/findPwd', this.parames, { isTokn: true, isURl: true })
        .then(() => {
          this.$toast('修改成功,请重新登录！');
          setTimeout(() => {
            this.$router.go(-1);
          }, 300);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.forgetPassword {
  width: 100%;
  height: 100vh;
  background: url('../assets/image/loginBk.png') no-repeat;
  background-size: 100% 100vh;
  .title {
    font-size: 48px;
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    color: #010100;
    padding-top: 301px;
    padding-left: 128px;
  }
  .box {
    width: 559px;
    background: #ffffff;
    border-radius: 30px 0px 0px 30px;
    padding: 0 64px 0 41px;
    margin-left: 86px;
    margin-top: 55px;
    .list {
      height: 146px;
      border-bottom: 1px solid #efefef;
      .listTitle {
        font-size: 28px;
        color: #666666;
        padding-top: 30px;
      }
      .listBox {
        font-size: 32px;
        color: #010100;
        position: relative;
        .passIcon {
          width: 38px;
          height: 38px;
          position: absolute;
          right: 0;
          top: 0;
        }
        .van-cell {
          padding: 0;
          margin-top: 20px;
        }
        .content {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          .van-icon {
            color: #d2d2d2;
          }
        }
      }
      .codeBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 32px;
        color: #010100;
        .left {
          .van-cell {
            padding: 0;
            margin-top: 20px;
          }
        }
        .right {
          font-size: 32px;
          color: #85c226;
        }
      }
    }
    .base {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;
      padding-bottom: 40px;
      .forget {
        font-size: 28px;
        color: #85c226;
      }
      .btn {
        width: 256px;
        height: 96px;
        font-weight: bold;
        font-family: PingFang-SC-Bold;
        font-size: 32px;
        .van-button--primary {
          color: #ffffff;
        }
        img {
          width: 38px;
          object-fit: contain;
          vertical-align: middle;
        }
      }
    }
  }
  .bar {
    width: 611px;
    height: 30px;
    background: #b9f55c;
    border-radius: 0px 0px 0px 30px;
    margin-left: 139px;
  }
}
</style>
